/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import {
  Fragment,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react';
import {
  IWidgetChild,
  IWidgetSchema,
  IWidgetSchemaTable,
  PublishPayloadData,
  TableSettingsState
} from '../IWidgetProps';
import { history, ISerializable, SearchParams } from '@netvision/lib-history';
import { CubeFilter, CubeQuery } from '@netvision/lib-api-repo';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { Dialog } from 'primereact/dialog';
import { ProgressBar } from 'primereact/progressbar';
import { PageState, PaginatorProps } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { useWidgetProps } from '../Root';
import { TablePagination } from './TablePagination';
import { EventTableSettings } from './EventTableSettings';
import { useApiRepository } from '../hooks/useApiRepository';
import { useLocale } from '../hooks/useLocale';
import { name } from '../../package.json';

type PaginatorPropsExtended = PaginatorProps & PageState
type EventTableProps = {
  searchParams: ISerializable
  tableQueryConfig: CubeQuery
  setSearchParams: React.Dispatch<React.SetStateAction<ISerializable>>
  setTableQueryConfig: React.Dispatch<Partial<CubeQuery>>
  observerID: string
  eventBusID: string
  toastRef: MutableRefObject<Toast>
}

export const EventTable = ({
  tableQueryConfig,
  searchParams,
  setSearchParams,
  setTableQueryConfig,
  observerID,
  eventBusID,
  toastRef
}: EventTableProps) => {
  const mountingPointRef: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const { api } = useApiRepository()
  const { journalTitle, actions, toastContent } = useLocale()
  const { mountChildren, areas, props } = useWidgetProps()
  const [isExportModalActive, setIsExportModalActive] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [widgetProps, setWidgetProps] = useState<IWidgetChild>(null!)
  const [tableData, setTableData] = useState<Record<string, unknown>[]>(null!)
  const [tableMetadata, setTableMetadata] = useState<IWidgetSchema>(null!)
  const [filterFields, setFilterFields] = useState<Set<string>>(null!)
  const [totalRecords, setTotalRecords] = useState(0)
  const [tableSettingsState, setTableSettingsState] = useReducer(
    (data: TableSettingsState, payload: Partial<TableSettingsState>) => ({ ...data, ...payload }),
    { isOpened: false, columns: null!, groups: null!, order: null! }
  )  
  const [tablePagination, setTablePagination] = useReducer(
    (data: PaginatorPropsExtended, payload: Partial<PaginatorPropsExtended>) => ({ ...data, ...payload }),
    null!
  )  
  const currentWidget = useMemo(() => (
    areas.find(({ name }) => name === 'table')
      ?.children.find(({ src }) => src === '@netvision/widget-react-table')
  ), [areas])
  const pagination = useMemo(() => ({
    ...tablePagination, totalRecords
  }), [tablePagination, totalRecords])
  const isFiltersEnabled = useMemo(() => (
    searchParams
      ? Object.keys(searchParams).filter((key) => (
        !['offset', 'limit', 'eventId'].includes(key)
      )).length > 0
      : false
  ), [searchParams])

  const getTableData = async () => {
    if (!('cubeGetEntities' in api) || api.cubeGetEntities === undefined) {
      throw new Error('cubeGetEntities method isn\'t implemented')
    }

    setIsLoading(true)

    try {
      const { results: events } = await api
        .cubeGetEntities<Record<string, unknown>>(
          tableQueryConfig,
          props.entityName
        )

      setTableData(events)
      setIsLoading(false)

    } catch (error) {
      console.error(error)
      toastRef.current.show({
        severity: 'error',
        summary: toastContent.error,
        detail: toastContent.dataLoadingError
      })
      setIsLoading(false)
    }
  }

  const getTableMetadata = async () => {
    try {
      const { results } = await api.getEntitiesList<IWidgetSchema>({
        limiter: {
          id: 'EntityTypeMetadata:CameraLocking',
          type: "EntityTypeMetadata",
        },
      });
      const metadata = Array.isArray(results) ? results[0] : results;
      setTableMetadata(metadata)
      if (!tableSettingsState.columns) {
        setTableSettingsState({
          columns: metadata.viewBlockingCameraJournal.columns,
          groups: metadata.viewBlockingCameraJournal.groups || [],
          order: metadata.viewBlockingCameraJournal.order || []
        })
        updateTableSettingsCache({
          columns: metadata.viewBlockingCameraJournal.columns,
          groups: metadata.viewBlockingCameraJournal.groups || [],
          order: metadata.viewBlockingCameraJournal.order || []
        })
      } else {
        const actualized = actualizedMetadata(metadata.viewBlockingCameraJournal, tableSettingsState)
        setTableSettingsState(actualized)
        updateTableSettingsCache({
          columns: actualized.columns,
          groups: actualized.groups,
          order: actualized.order
        })
      }
    } catch (error: unknown) {
      console.error(error);
      toastRef.current.show({
        severity: 'error',
        summary: toastContent.error,
        detail: toastContent.metadataLoadingError
      })
      return
    }
  }

  const actualizedMetadata = (remote: IWidgetSchemaTable, local: TableSettingsState) => {
    return {
      isOpened: local.isOpened,
      groups: remote.groups,
      order: remote.order,
      columns: Object.entries(remote.columns)
        .reduce<IWidgetSchemaTable['columns']>((acc, [columnKey, columnValue]) => {
          if (!local.columns[columnKey]) {
            acc[columnKey] = columnValue
          } else {
            acc[columnKey] = {
              ...columnValue,
              hidden: local.columns[columnKey].hidden ?? false
            }
          }
          return acc
        }, {})
    }
  }

  const getTableDataCount = async () => {
    if (!('cubeGetQuantity' in api) || api.cubeGetQuantity === undefined) {
      throw new Error('cubeGetQuantity method isn\'t implemented')
    }

    try {
      const countConfig = {
        ...tableQueryConfig,
        dimensions: [],
        measures: [`${props.entityName}.count`]
      }
      delete countConfig.limit
      delete countConfig.order
      const count = await api.cubeGetQuantity(countConfig, props.entityName) || 0
      if (count !== totalRecords) {
        setTotalRecords(count)
      }
    } catch (error) {
      console.error(error)
      toastRef.current.show({
        severity: 'error',
        summary: toastContent.error,
        detail: toastContent.countLoadingError
      })
    }
  }

  const initTableSettingsState = () => {
    const cachedColumnsState = localStorage.getItem(`${name}:table-settings`)
    if (cachedColumnsState) {
      const parsedCachedSettings = JSON.parse(cachedColumnsState)
      setTableSettingsState({ ...parsedCachedSettings })
    } else {
      getTableMetadata()
    }
  }

  const updateTableSettingsCache = (payload: IWidgetSchemaTable) => {
    const cachedColumnsState = localStorage.getItem(`${name}:table-settings`)
    if (!cachedColumnsState) {
      localStorage.setItem(`${name}:table-settings`, JSON.stringify(payload))
    } else {
      const parsedCachedSettings = JSON.parse(cachedColumnsState)
      localStorage.setItem(`${name}:table-settings`, JSON.stringify({
        ...parsedCachedSettings,
        ...payload
      }))
    }
  }

  const cleanFilterProps = <T extends PublishPayloadData<ISerializable>['payload']>(payload: T | ISerializable) => {
    return Object.entries(payload)
      .reduce<Record<string, any>>((acc, [key, value]) => {
        if (value) acc[key] = value
        return acc
      }, {})
  }

  const updatePagination = (payload: Partial<PaginatorPropsExtended>, isForcedReload = false) => {
    const payloadKeys = Object.keys(payload) as Array<keyof PaginatorPropsExtended>
    const isReallyChanged = payloadKeys.some((key) => (
      key in tablePagination && tablePagination[key] !== payload[key]
    ))
    if (isReallyChanged || isForcedReload) setTablePagination(payload)
  }

  const isPositiveInteger = (value: any): value is number => (
    typeof value === 'number' && !isNaN(value) && Number.isInteger(value) && value > 0
  )

  const getQueryOperator = useCallback((keyParts: string[]): any => {
    const queryKeyDict = new Map([
      ['startDt', 'inDateRange'],
      ['endDt', 'inDateRange'],
      ['updatedAt', 'inDateRange']
    ])
    return queryKeyDict.get(keyParts[0]) || 'contains'
  }, [])

  const getQueryValues = useCallback((keyParts: string[], values: unknown) => {
    if (!values) return []
    switch(keyParts[0]) {
      case 'startDt':
      case 'endDt':
      case 'updatedAt':
        return String(values)
          .split(',')
          .map((val) => new Date(parseInt(val)).toUTCString())
          .flat()
      default:
        return String(values)
          .split(',')
          .map((val) => [
            val,
            val.toUpperCase(),
            val.toLowerCase(),
            val[0]?.toUpperCase() + val.substring(1)?.toLowerCase()
          ])
          .flat()
    }
  }, [])

  const combineQueryFilters = () => {
    const searchParams = SearchParams.parse(history.location.search)
    return Object.entries(searchParams).reduce<CubeFilter[]>((acc, [key, value]) => {
      const keyParts = key.split('.')
      if (filterFields.has(keyParts[0])) {
        acc.push({
          member: tableMetadata.viewBlockingCameraJournal.columns?.[keyParts[0]]?.filter?.field as string,
          operator: getQueryOperator(keyParts),
          values: getQueryValues(keyParts, value)
        })
      }
      return acc
    }, [])
  }

  const setTimeFilters = (data: PublishPayloadData<Record<string, { mode: string; values: number[] }>>) => {
    const timePayload = Object.entries(data.payload)
      .reduce<Record<string, string>>((acc, [key, value]) => {
        acc[`${key}.${value.mode}`] = value.values.join(',')
        return acc
      }, {})
    setSearchParams((prev) => {
      return cleanFilterProps({
        ...prev,
        ...timePayload,
        offset: 0
      })
    })
  }

  const setSimpleFilters = (data: PublishPayloadData<ISerializable>) => {
    setSearchParams((prev) => {
      return cleanFilterProps({
        ...prev,
        ...data.payload,
        offset: 0
      })
    })
  }

  const updateFilter = <T,>(payload: PublishPayload<T>) => {
    // @ts-ignore
    switch(payload.data.sortingField) {
      case 'startDt':
      case 'endDt':
      case 'updatedAt':
      case `${props.entityName}.startDt`:
      case `${props.entityName}.endDt`:
      case `${props.entityName}.updatedAt`:
        setTimeFilters(payload.data as PublishPayloadData<Record<string, { mode: string; values: number[] }>>)
        break
      default:
        setSimpleFilters(payload.data as PublishPayloadData<ISerializable>)
    }
    setTablePagination({ first: 0 })
  }

  const switchTableSettingsVisibility = () => {
    setTableSettingsState({ isOpened: !tableSettingsState.isOpened })
    history.push({
      search: SearchParams.stringify(searchParams),
      hash: !tableSettingsState.isOpened ? '#settings' : ''
    })
  }

  const updateSettingsState = (
    column: IWidgetSchemaTable['columns'],
    groups?: IWidgetSchemaTable['groups'],
    order?: IWidgetSchemaTable['order']
  ) => {
    setTableSettingsState({
      columns: {
        ...tableSettingsState.columns,
        ...column
      },
      groups: groups || tableSettingsState.groups,
      order: order || tableSettingsState.order
    })
  }

  useEffect(() => {
    initTableSettingsState()
    const searchParams = SearchParams.parse(history.location.search)
    const formattedOffset = isPositiveInteger(searchParams.offset) ? +searchParams.offset : 0
    const initialPagination = {
      rows: searchParams.limit ? Number(searchParams.limit) : props.cubeListQuery.limit || 10,
      rowsPerPageOptions: [10, 25, 50, 100]
    }
    setSearchParams(searchParams)
    setTablePagination({
      ...initialPagination,
      first: formattedOffset
    })
  }, [])

  useEffect(() => {
    if (tableMetadata) {
      const filterKeys = Object.entries(tableMetadata.viewBlockingCameraJournal.columns || {})
        .reduce<Set<string>>((acc, [key, value]) => {
          if ('filter' in value) acc.add(key)
          return acc
        }, new Set())
      setFilterFields(filterKeys)
    }
  }, [tableMetadata])

  useEffect(() => {
    if (filterFields) {
      setTableQueryConfig({
        ...props.cubeListQuery,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        filters: combineQueryFilters(),
        offset: tablePagination.first,
        limit: tablePagination.rows
      })
    }
  }, [filterFields, tablePagination])

  useEffect(() => {
    if (tableQueryConfig) getTableData()
  }, [tableQueryConfig])

  useEffect(() => {
    if (searchParams) {
      if (history.location.hash === '#settings') {
        setTableSettingsState({ isOpened: true })
      }
      history.push({ search: SearchParams.stringify(searchParams), hash: history.location.hash })
    }
  }, [searchParams, history])

  useEffect(() => {
    if (!tableData) return
    if (!currentWidget) throw new Error('Widget is not defined')
    if (!widgetProps) {
      setWidgetProps({
        ...currentWidget,
        props: {
          ...currentWidget.props,
          schema: {
            ...tableMetadata,
            viewBlockingCameraJournal: {
              ...tableMetadata.viewBlockingCameraJournal,
              columns: tableSettingsState.columns
            }
          },
          data: tableData
        }
      })
      getTableDataCount()
    } else if (tableData) {
      props.observer.notify(observerID, tableData)
      tablePagination.first === 0 && getTableDataCount()
    }
  }, [tableData, tableSettingsState.columns])

  useEffect(() => {
    if (!widgetProps) return
    const { current: mountingPoint } = mountingPointRef;
    if (mountingPoint && currentWidget) {      
      const schema = widgetProps.props.schema as Record<string, object>
      return mountChildren(mountingPoint, [{
        ...widgetProps,
        props: {
          ...widgetProps.props,
          schema: {
            ...schema,
            viewTable: schema.viewBlockingCameraJournal
          },
          observerID,
          eventBusID
        }
      }]);
    }
    return;
  }, [widgetProps]);

  useEffect(() => {
    if (eventBusID) {
      props.eventBus.subscribe(eventBusID, 'filterUpdate', updateFilter)
    }

    return () => {
      eventBusID && props.eventBus.unsubscribe(eventBusID, 'filterUpdate')
    }
  }, [props.eventBus, eventBusID])

  useEffect(() => {
    tableSettingsState.columns && !tableMetadata && getTableMetadata()
    if (!widgetProps) return
    props.eventBus.notify(eventBusID, 'config:changed', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: tableSettingsState.columns
    })
    updateTableSettingsCache({
      columns: tableSettingsState.columns,
      groups: tableSettingsState.groups || []
    })
  }, [tableSettingsState])

  return (
    <div style={{ overflow: 'hidden' }}>
      <main css={mainCSS} id="eventTableMain">
        <header css={headerCSS}>
          <div className="inner">
            <h1>{journalTitle}{tableSettingsState.isOpened && `: ${actions.openSettings.toLowerCase()}`}</h1>
            <div className="inner-actions">
              {tableSettingsState.isOpened
                ? <Button
                    className="p-button-secondary p-button-outlined p-button-sm"
                    icon="mdi mdi-18px mdi-arrow-left p-c"
                    tooltip={actions.back}
                    onClick={switchTableSettingsVisibility}
                  />
                : <Fragment>
                    <Button
                      className="p-button-secondary p-button-outlined p-button-sm"
                      icon="mdi mdi-18px mdi-cog p-c"
                      tooltip={actions.openSettings}
                      onClick={switchTableSettingsVisibility}
                    />
                    {isFiltersEnabled &&
                      <Button
                        className="p-button-outlined p-button-sm"
                        icon="mdi mdi-18px mdi-filter-remove p-c"
                        tooltip={actions.resetFilters}
                        onClick={() => {
                          setSearchParams(cleanFilterProps({ offset: 0, limit: searchParams.limit || 0 }))
                          setTimeout(() => updatePagination({ first: 0 }, true), 0)
                        }}
                      />
                    }
                  </Fragment>
              }
            </div>
          </div>
        </header>
        <div css={tabPanelCSS}>
          <ProgressBar
            mode="indeterminate"
            style={{ height: 'calc(2rem / var(--bfs))', opacity: isLoading ? 1 : 0 }}
          />
          <div
            ref={mountingPointRef}
            css={mountPointCSS}
            className={isLoading ? '--loading' : '--ready'}
            style={{
              visibility: tableSettingsState.isOpened ? 'hidden' : 'visible',
              opacity: isLoading ? 0.5 : 1,
              pointerEvents: isLoading ? 'none' : 'all'
            }}
          />          
          {tableSettingsState.isOpened
            ? <EventTableSettings
                isInactive={isLoading}
                order={tableSettingsState.order}
                columns={tableSettingsState.columns}
                locales={tableMetadata?.locales}
                setSettingsState={updateSettingsState}
                setDefaultColumnConfig={() => updateSettingsState(
                  tableMetadata.viewBlockingCameraJournal.columns,
                  tableMetadata.viewBlockingCameraJournal.groups,
                  tableMetadata.viewBlockingCameraJournal.order
                )}
              />
            : <TablePagination
                isInactive={isLoading || pagination.totalRecords === 0}
                pagination={pagination}
                paginationChanged={(props) => {
                  updatePagination(props)
                  setSearchParams(cleanFilterProps({
                    ...searchParams,
                    offset: props.first,
                    limit: props.rows
                  }))
                }}
                refresh={getTableData}
                exportData={() => setIsExportModalActive(true)}
              />
          }
        </div>
      </main>
      <Dialog
        appendTo={document.getElementById('eventTableMain') || document.body}
        visible={isExportModalActive}
        onHide={() => setIsExportModalActive(false)}
        header={actions.export}
      />
    </div>
  )
}

const mainCSS = css`
  margin-top: calc(94rem/var(--bfs));
  margin-bottom: calc(30rem/var(--bfs));
  height: calc(100vh - 124rem/var(--bfs));
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;

  .p-dialog-mask:not(.p-dialog-visible) {
    z-index: -1 !important;
  }
`

const headerCSS = css`
  padding: 0 calc(30rem/var(--bfs));

  .inner {
    position: relative;
    display: flex;
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      bottom: calc(-2rem / var(--bfs));
      left: 0px;
      height: calc(1rem / var(--bfs));
      width: 100%;
      background: var(--text-color-secondary);
    }

    &-actions {
      margin-left: var(--spacer);
      display: grid;
      gap: var(--spacer-xs);
      grid-auto-flow: column;
    }
  }

  h1 {
    font-size: var(--font-size-h1);
    font-weight: 500;
    margin: 0;
    padding: calc(20rem / var(--bfs)) 0;
    position: relative;

    &:after {
      content: "";
      left: 0px;
      width: 100%;
      z-index: 1;
      position: absolute;
      bottom: calc(-5rem / var(--bfs));      
      height: calc(4rem / var(--bfs));
      background: var(--primary-color);
    }
  }
`

const tabPanelCSS = css`
  padding: 0 calc(30rem/var(--bfs));
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
  position: relative;
`

const mountPointCSS = css`
  margin-top: 2rem;
  font-size: 2rem;
  overflow: hidden;

  .single-spa-parcel-container {
    height: 100%;
    overflow: hidden;

    & > div {
      margin: 0;
      padding: 0;
      height: 100%;
      overflow: hidden;
    }
  }
`
