import React, { createContext, useContext } from 'react';
import { LocaleProvider } from './hooks/useLocale';
import { Main } from './components/Main';
import { IWidgetConfig } from './IWidgetProps';
import { ApiRepositoryProvider } from './hooks/useApiRepository'

const WidgetPropsContext = createContext<IWidgetConfig>(null!);

export function useWidgetProps() {
  return useContext(WidgetPropsContext);
}

const Root = (config: IWidgetConfig) => {
  return (
    <ApiRepositoryProvider lib={config.props?.lib}>
      <RootContent config={config} />
    </ApiRepositoryProvider>
  );
};

const RootContent = ({ config }: { config: IWidgetConfig }) => {
  return (
    <WidgetPropsContext.Provider value={config}>
      <LocaleProvider>
        <Main />
      </LocaleProvider>
    </WidgetPropsContext.Provider>
  )
}

export default Root;
