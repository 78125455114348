import React, { useRef, useState, useReducer, useEffect } from 'react'
import { ISerializable } from '@netvision/lib-history';
import { CubeQuery } from '@netvision/lib-api-repo';
import { Toast } from 'primereact/toast';
import { useWidgetProps } from '../Root';
import { EventTable } from './EventTable';
import { useGetUserInfo } from '../hooks/useGetUserInfo';

export const Main = () => {
  const toastRef = useRef<Toast>(null!);
  const [observerID, setObserverID] = useState<string>(null!)
  const [eventBusID, setEventBusID] = useState<string>(null!)
  const [searchParams, setSearchParams] = useState<ISerializable>(null!)
  const { userId = null } = useGetUserInfo();
  const { props: { observer, eventBus } } = useWidgetProps()
  const [tableQueryConfig, setTableQueryConfig] = useReducer(
    (config: CubeQuery, payload: Partial<CubeQuery>): CubeQuery => ({ ...config, ...payload }),
    null!
  )

  useEffect(() => {
    setObserverID(observer.addUniqueSubscriberSet())
    setEventBusID(eventBus.addUniqueSubscriberMap())
  }, [])

  return (
    <div>
      {userId &&
        <EventTable
          searchParams={searchParams}
          tableQueryConfig={tableQueryConfig}
          setSearchParams={(params) => setSearchParams(params)}
          setTableQueryConfig={setTableQueryConfig}
          observerID={observerID}
          eventBusID={eventBusID}
          toastRef={toastRef}
        />
      }
      <Toast ref={toastRef} position="bottom-left" />
    </div>
  )
}