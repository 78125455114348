/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Button } from 'primereact/button';
import { Paginator, PaginatorProps } from 'primereact/paginator';
import { useLocale } from '../hooks/useLocale';

type TablePaginationProps = {
  pagination: PaginatorProps
  isInactive: boolean
  paginationChanged: (payload: PaginatorProps) => void
  refresh: () => void
  exportData: () => void
}

export const TablePagination = ({
  pagination,
  isInactive,
  paginationChanged,
  refresh,
  exportData
}: TablePaginationProps) => {
  const { actions } = useLocale()

  const RightContent = (
    <div css={contentSetCSS}>
      <Button
        icon="mdi mdi-18px mdi-refresh p-c"
        onClick={refresh}
        tooltip={actions.refresh}
        tooltipOptions={{ position: 'top' }}
      />
      {/* <Button
        icon="mdi mdi-18px mdi-database-export p-c"
        onClick={exportData}
        tooltip={actions.export}
        tooltipOptions={{ position: 'top' }}
      /> */}
    </div>
  )

  return (
    <Paginator
      { ...pagination }
      onPageChange={paginationChanged}
      template="RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      rightContent={RightContent}
      css={rightContentCSS}
      style={{
        opacity: isInactive ? 0.5 : 1,
        pointerEvents: isInactive ? 'none' : 'all'
      }}
    />
  )
}

const rightContentCSS = css`
  .p-paginator-right-content {
    margin-right: 0
  }
`

const contentSetCSS = css`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: calc(10rem / var(--bfs));
`
