import { IUserInfo } from '@netvision/lib-api-repo/dist/src/types';
import { useEffect, useState } from 'react';
import { useApiRepository } from './useApiRepository';

export const useGetUserInfo = () => {
  const [userInfo, setUserInfo] = useState<IUserInfo>({} as IUserInfo);
  const { api } = useApiRepository()

  useEffect(() => {
    const fetchUserInfo = () => {
      'getUserInfo' in api &&
        api.getUserInfo().then((response) => {
          if ('userId' in response) {
            setUserInfo(response);
          }
        });
    };

    try {
      fetchUserInfo();
    } catch (e) {
      console.error(e);
    }
  }, []);

  return userInfo;
};
