export const common = {
  from: 'из',
  allowed: 'допустимых',
  search: 'Поиск',
  noneText: 'Отсутствует',
  anyText: 'Любое значение'
};

export const actions = {
  refresh: 'Обновить',
  export: 'Экспорт',
  cancel: 'Отмена',
  back: 'Назад',
  resetFilters: 'Сбросить фильтры',
  openSettings: 'Настройки',
  byDefault: 'По умолчанию'
}

export const toastContent = {
  content: 'Событие не найдено',
  error: 'Ошибка',
  metadataLoadingError: 'Не удалось получить метаданные',
  dataLoadingError: 'Не удалось получить данные',
  countLoadingError: 'Не удалось получить данные о количестве элементов'
}

export const defaultLocale = {
  journalTitle: 'Журнал блокировок',
  settingsColumnTitle: 'Отображать столбцы',
  ...common,
  actions,
  toastContent
};

export type Locale = typeof defaultLocale;
